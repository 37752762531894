const CreativeAgencyNewFeedback = () => {
  return (
    <>
      <div className="sections feedback-section ">
        <div className="sections__head">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="text-center text-white mb-0">
                  Our Wall of Love 💛
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 col-lg-4">
              <div className="row g-4">
                <div className="col-12">
                  <div className="card twitter-card p-4 d-flex flex-column justify-content-between">
                    <div >
                      {/* <span className="d-block twitter-card__user mb-1">
                      Satisfied IT Consultant Client
                     
                      </span> */}
                     
                      <p className="twitter-card__msg">
                      Senertech has been an invaluable partner in helping us navigate the complex world of IT. Their team of experts provided us with insightful consultations, tailored solutions, and ongoing support. Thanks to their guidance, we've been able to streamline our operations and improve our overall efficiency.
                      </p>
                      </div>
                      <span className="d-block twitter-card__user">
                      Sumit Goswami
                      </span>
                    </div>
                  </div>                
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="row g-4">
                <div className="col-12">
                <div className="card twitter-card p-4 d-flex flex-column justify-content-between">
                  <div>
        
                      {/* <span className="d-block twitter-card__user mb-1">
                      Thrilled Video Production Client
                     
                      </span> */}
                     
                      <p className="twitter-card__msg">
                      We needed professional videos to showcase our products, and Senertech delivered beyond our expectations. Their team was creative, efficient, and easy to work with. The final videos were of exceptional quality and helped us attract new customers.
                      </p>
                      </div>
                      <span className="d-block twitter-card__user">
                      Radha Mathur
                      </span>
                    </div>
                  
                </div>
               
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="row g-4">
                <div className="col-12">
                <div className="card twitter-card p-4 d-flex flex-column justify-content-between ">
                    <div >
                      {/* <span className="d-block twitter-card__user mb-1">
                      Impressed Graphic Design Client
                     
                      </span> */}
                     
                      <p className="twitter-card__msg">
                      Senertech's graphic design team has a knack for creating visually stunning designs that capture the essence of our brand. They've helped us revamp our website, create marketing materials, and develop a consistent brand identity. We're extremely satisfied with their work.
                      </p>
                      </div>
                      <span className="d-block twitter-card__user">
                      Anjali Sharma
                      </span>
                   
                  </div>
                </div>
               
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="row g-4">
                <div className="col-12">
                <div className="card twitter-card p-4 d-flex flex-column justify-content-between ">
                    <div >
                      {/* <span className="d-block twitter-card__user mb-1">
                      Impressed Graphic Design Client
                     
                      </span> */}
                     
                      <p className="twitter-card__msg">
                      We were struggling to increase our online visibility until we partnered with SenerTech. Their digital marketing strategies have been incredibly effective. Our website traffic has skyrocketed, and we've seen a significant boost in leads and sales. I highly recommend them!                      </p>
                      </div>
                      <span className="d-block twitter-card__user">
                      Shivam Chaudhary
                      </span>
                   
                  </div>
                </div>
               
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="row g-4">
                <div className="col-12">
                <div className="card twitter-card p-4 d-flex flex-column justify-content-between ">
                    <div >
                      {/* <span className="d-block twitter-card__user mb-1">
                      Impressed Graphic Design Client
                     
                      </span> */}
                     
                      <p className="twitter-card__msg">
                      Senertech has taken our social media presence to the next level. Their team is always up-to-date on the latest trends and knows how to create engaging content that resonates with our target audience. We've seen a significant increase in engagement and brand awareness thanks to their expertise.
                      </p>
                      </div>
                      <span className="d-block twitter-card__user">
                      Karan Chaurasia
                      </span>
                   
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
    
      </div>
    </>
  );
};

export default CreativeAgencyNewFeedback;
