import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";
const MarketplaceAgencyFeedback = () => {
  return (
    <>
      <section className="bg-light-subtle pt-50 pb-50 position-relative z-1 overflow-hidden">
        <img
          src="assets/img/home_37/feedback-shape.png"
          alt="image"
          className="ma-feedback-bg img-fluid position-absolute start-0 bottom-0 z-n1"
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="d-flex justify-content-center">
                <div className="d-inline-block px-4 py-1 rounded-pill border border-warning mb-2">
                  <p className="ma-warning-text fw-bold mb-0">Testimonial</p>
                </div>
              </div>
              <div className="text-center">
                <h2 className="ail-title text-black fs-48 fw-600">
                  What our clients say
                </h2>
              </div>
            </div>
          </div>
          <div className="mt-30">
            <div className="row">
              <div className="col-12">
                <Swiper
                  modules={[Navigation,Autoplay]}
                  autoplay={{ delay: 2000 }}
                  loop={true}
                  navigation={{
                    nextEl: ".aiart-swiper-button-next",
                    prevEl: ".aiart-swiper-button-prev",
                  }}
                  breakpoints={{
                    840: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    1300: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1400: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  className="aiart-post-slider ma-style"
                >
                  <SwiperSlide className="swiper-slide">
                    <div className="ma-feedback-item bg-white px-4 py-4 rounded shadow-sm d-flex flex-column justify-content-between digital-marketing-card" > 
                      <div>
                      {/* <h6>Satisfied IT Consultant Client</h6> */}
                      <p className="text-dark fs-18 fw-medium">
                      Senertech has been an invaluable partner in helping us navigate the complex world of IT. Their team of experts provided us with insightful consultations, tailored solutions, and ongoing support. Thanks to their guidance, we've been able to streamline our operations.
                      {/* and improve our overall efficiency. */}
                      </p>
                      </div>
                      <h6 className="mb-0">Sumit Goswami</h6>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide" >
                    <div className="ma-feedback-item bg-white px-4 py-4 rounded shadow-sm d-flex flex-column justify-content-between digital-marketing-card" > 
                      <div>
                      {/* <h6>Happy Digital Marketing Client</h6> */}
                      <p className="text-dark fs-18 fw-medium">
                      We were struggling to increase our online visibility until we partnered with SenerTech. Their digital marketing strategies have been incredibly effective. Our website traffic has skyrocketed, and we've seen a significant boost in leads and sales. I highly recommend them!
                      </p>
                      </div>
                      <h6 className="mb-0">Shivam Chaudhary</h6>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide" >
                    <div className="ma-feedback-item bg-white px-4 py-4 rounded shadow-sm d-flex flex-column justify-content-between digital-marketing-card" > 
                      <div>
                      {/* <h6>Delighted Social Media Management Client</h6> */}
                      <p className="text-dark fs-18 fw-medium">
                      Senertech has taken our social media presence to the next level. Their team is always up-to-date on the latest trends and knows how to create engaging content that resonates with our target audience. We've seen a significant increase in engagement and brand awareness.
                      {/* thanks to their expertise. */}
                      </p>
                      </div>
                      <h6 className="mb-0">Karan Chaurasia </h6>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide" >
                    <div className="ma-feedback-item bg-white px-4 py-4 rounded shadow-sm d-flex flex-column justify-content-between digital-marketing-card"> 
                      <div>
                      {/* <h6>Thrilled Video Production Client</h6> */}
                      <p className="text-dark fs-18 fw-medium">
                      We needed professional videos to showcase our products, and Senertech delivered beyond our expectations. Their team was creative, efficient, and easy to work with. The final videos were of exceptional quality and helped us attract new customers.
                      </p>
                      </div>
                      <h6 className="mb-0">Radha Mathur</h6>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide" >
                    <div className="ma-feedback-item bg-white px-4 py-4 rounded shadow-sm d-flex flex-column justify-content-between digital-marketing-card "> 
                      <div>
                      {/* <h6>Impressed Graphic Design Client</h6> */}
                      <p className="text-dark fs-18 fw-medium">
                      Senertech's graphic design team has a knack for creating visually stunning designs that capture the essence of our brand. They've helped us revamp our website, create marketing materials, and develop a consistent brand identity. We're extremely satisfied with their work.
                      </p>
                      </div>
                      <h6 className="mb-0">Anjali Sharma</h6>
                    </div>
                  </SwiperSlide>                
                </Swiper>
                {/* <div className="d-flex align-items-center justify-content-center gap-4 mt-5">
                  <div className="aiart-slider-nav aiart-swiper-button-prev">
                    <i className="fas fa-arrow-left fs-16"></i>
                  </div>
                  <div className="aiart-slider-nav aiart-swiper-button-next">
                    <i className="fas fa-arrow-right fs-16"></i>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MarketplaceAgencyFeedback;
