import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
SwiperCore.use([Navigation]);

const CryptoTestimonial = () => {
  const swiperOption = {
    slidesPerView: 2,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 2,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },

      991: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  };
  return (
    <section className="crypto-testimonial  bg-dark-black pt-60 pb-120">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="section-title mb-5">
              <h2 className="text-white">What they say about us</h2>
              <p className="text-white">
              Discover what our clients have to say about their experience with us. Their success stories inspire us to keep delivering exceptional service..
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <Swiper {...swiperOption}>
            <SwiperSlide>            
              <div className="crypto-single-testimonial bg-soft-black d-flex flex-column justify-content-between" style={{height:"330px"}}>
                <div>
                  <ul className="review-rate mb-0 list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                </ul>
                {/* <h5 className="fw-normal text-white h6 mt-2 mb-0">Satisfied IT Consultant Client</h5> */}
                <blockquote className="m-0">
                  <p className="m-0">
                    Senertech has been an invaluable partner in helping us navigate the complex world of IT. Their team of experts provided us with insightful consultations, tailored solutions, and ongoing support. Thanks to their guidance, we've been able to streamline our operations and improve our overall efficiency.
                  </p>
                </blockquote>
                </div>
                  <div>
                    <h5 className="fw-normal text-white h6 m-0">
                    Sumit Goswami
                    </h5>
                  </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>            
              <div className="crypto-single-testimonial bg-soft-black d-flex flex-column 
              justify-content-between" style={{height:"330px"}}>
                <div>
                <ul className="review-rate mb-0 list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                </ul>
                {/* <h5 className="fw-normal text-white h6 mt-2 mb-0">Happy Digital Marketing Client</h5> */}
                <blockquote className="m-0">
                  <p className="m-0">
                  We were struggling to increase our online visibility until we partnered with Senertech. Their digital marketing strategies have been incredibly effective. Our website traffic has skyrocketed, and we've seen a significant boost in leads and sales. I highly recommend them!
                  </p>
                </blockquote>
                </div>
                  <div>
                    <h5 className="fw-normal text-white h6 m-0">
                    Shivam Chaudhary
                    </h5>
                  </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>            
              <div className="crypto-single-testimonial bg-soft-black d-flex 
              flex-column justify-content-between" style={{height:"330px"}}>
                <div>
                <ul className="review-rate mb-0 list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                </ul>
                {/* <h5 className="fw-normal text-white h6 mt-2 mb-0">Delighted Social Media Management Client</h5> */}
                <blockquote className="m-0">
                  <p className="m-0">
                  Senertech has taken our social media presence to the next level. Their team is always up-to-date on the latest trends and knows how to create engaging content that resonates with our target audience. We've seen a significant increase in engagement and brand awareness thanks to their expertise.
                  </p>
                </blockquote>
                </div>
                  <div>
                    <h5 className="fw-normal text-white h6 m-0">
                    Karan Chaurasia
                    </h5>
                  </div>
              </div>
            </SwiperSlide>
              <SwiperSlide>            
              <div className="crypto-single-testimonial bg-soft-black d-flex flex-column 
              justify-content-between" style={{height:"330px"}}>
                <div>
                <ul className="review-rate mb-0 list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                </ul>
                {/* <h5 className="fw-normal text-white h6 mt-2 mb-0">Thrilled Video Production Client Client</h5> */}
                <blockquote className="m-0">
                  <p className="m-0">
                  We needed professional videos to showcase our products, and Senertech delivered beyond our expectations. Their team was creative, efficient, and easy to work with. The final videos were of exceptional quality and helped us attract new customers.
                  </p>
                </blockquote>
                </div>
                  <div>
                    <h5 className="fw-normal text-white h6 m-0">
                     Radha Mathur
                    </h5>
                  </div>
              </div>
            </SwiperSlide> 
            <SwiperSlide>            
              <div className="crypto-single-testimonial bg-soft-black d-flex flex-column 
              justify-content-between" style={{height:"330px"}}>
                <div>
                <ul className="review-rate mb-0 list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-star text-warning"></i>
                  </li>
                </ul>
                {/* <h5 className="fw-normal text-white h6 mt-2 mb-0">Impressed Graphic Design Client</h5> */}
                <blockquote className="m-0">
                  <p className="m-0">
                  Senertech's graphic design team has a knack for creating visually stunning designs that capture the essence of our brand. They've helped us revamp our website, create marketing materials, and develop a consistent brand identity. We're extremely satisfied with their work.
                  </p>
                </blockquote>
                </div>
                  <div>
                    <h5 className="fw-normal text-white h6 m-0">
                    Anjali Sharma
                    </h5>
                  </div>
              </div>
            </SwiperSlide> 
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default CryptoTestimonial;
