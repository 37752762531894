import React from 'react';
import { Link } from 'react-router-dom';

const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${footerGradient ? 'bg-gradient' : ''
            }  text-white ptb-40`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
                <div className='col-md-3 col-lg-3 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <p className='fw-bold text-center'>SERVICES</p>
                      <ul className='list-unstyled footer-nav-list mb-lg-0 text-center'>
                        <li>
                          <Link
                            to='/it-consultation'
                            className='text-decoration-none fw-light'>
                            IT Consultation
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/web-devlopment'
                            className='text-decoration-none fw-light'>
                            Web & App Development
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/digital-marketing'
                            className='text-decoration-none fw-light'
                          >
                            Digital Marketing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/graphic-designing'
                            className='text-decoration-none fw-light'
                          >
                            Graphic Designing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/social-media-managment'
                            className='text-decoration-none fw-light'
                          >
                            Social Media Management
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/video-marketing-services'
                            className='text-decoration-none fw-light'
                          >
                            Video Making
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-3 col-lg-3 mt-4  mt-md-0 mt-lg-0'>
                    <div className='footer-single-col '>
                      <p className="text-uppercase fw-bold text-center">PRODUCTS</p>
                      <ul className='list-unstyled footer-nav-list mb-lg-0 text-center'>
                        <li>
                          <Link 
                          onClick={(e) => {
                            
                              e.preventDefault(); // Prevent default navigation behavior
                              window.open("/wms", '_blank'); // Open in a new tab
                            }}

                          className='text-decoration-none fw-light'>
                            WMS
                          </Link>
                        </li>
                        <li>
                          <Link 
                          title='Coming Soon ..'
                     
                          className='text-decoration-none fw-light text-muted'>
                            ABMS
                            <span className="ail-highlighted-text"> (Coming Soon)</span>
                          </Link>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                  
                  <div className='col-md-3 col-lg-3 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <p className='fw-bold text-center'>QUICK LINKS</p>
                      <ul className='list-unstyled footer-nav-list mb-lg-0 text-center'>
                        <li>
                          <Link
                          
                            className='text-decoration-none fw-light'
                          >
                            Career
                          </Link>
                        </li>
                        <li>
                          <Link
                         to='/contact-us'
                            className='text-decoration-none fw-light'
                          >
                            Contact
                          </Link>
                        </li>
                        <li>
                          <Link                           
                            className='text-decoration-none fw-light'
                          >
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link    
                            className='text-decoration-none fw-light'
                          >
                            Knowledgebase
                          </Link>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-3 col-lg-3 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <p className='fw-bold text-center'>HELP</p>
                      <ul className='list-unstyled footer-nav-list mb-lg-0 text-center'>
                        <li>
                          <Link
                          
                            className='text-decoration-none fw-light'
                          >
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link
                            className='text-decoration-none fw-light'
                          >
                            Refund Policy
                          </Link>
                        </li>
                        <li>
                          <Link                           
                            className='text-decoration-none fw-light'
                          >
                            Terms of Use
                          </Link>
                        </li>
                        <li>
                          <Link    
                            className='text-decoration-none fw-light'
                          >
                            Disclaimer
                          </Link>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${footerLight ? 'footer-light' : 'bg-dark'
            } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    Copyright © Senertech Software Private Limited. All Rights Reserved
                    {/* <Link
                      to='/https://themetags.com/'
                      className='text-decoration-none ms-2'
                    >
                      ThemeTags
                    </Link> */}
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <a href='https://www.facebook.com/senertech' target ="_blank">
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.instagram.com/sener.tech' target ="_blank">
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.linkedin.com/company/sener-tech'  target ="_blank">
                        <i className='fab fa-linkedin'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://x.com/sener_tech'  target ="_blank">
                        <i className='fa fa-twitter'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://www.youtube.com/@senertech'  target ="_blank">
                        <i className='fab fa-youtube'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
