const AiCompanyProcess = () => {
    return (
      <>
        <div className="aih-process-area ah-bg p-50 ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="text-center">
                  <h1 className="aih-title aih-color-two fw-600 mb-40">
                  Steps & the way we get things done{" "}
                  </h1>
                </div>
              </div>
            </div>
            <div className="aih-process-box bg-white border rounded-16 overflow-hidden">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-6">
                  <div className="aih-process-item p-4 aih-border-style position-relative "
                  // home-step-tiles
                   >
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="aih-color-two fs-24 fw-600 mb-0">
                        Briefing
                      </h5>
                      <p className="aih-step ca-two-body-clr fs-14 ff-dmsans fw-500 p-1 border rounded-16 mb-0">
                        Step 01
                      </p>
                    </div>
                    <p className="ca-two-body-clr mt-20 mb-0 text-center">
                    This is the initial stage where we understand your specific needs and requirements .
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="aih-process-item p-4 aih-border-style position-relative ">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="aih-color-two fs-24 fw-600 mb-0">
                        Processing
                      </h5>
                      <p className="aih-step ca-two-body-clr fs-14 ff-dmsans fw-500 p-1 border rounded-16 mb-0">
                        Step 02
                      </p>
                    </div>
                    <p className="ca-two-body-clr mt-20 mb-0 text-center">
                    Here magic happens; our team of experts works to analyze, design, and develop the solution according to your briefing.                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="aih-process-item p-4 aih-border-style position-relative ">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="aih-color-two fs-24 fw-600 mb-0">
                        Finishing
                      </h5>
                      <p className="aih-step ca-two-body-clr fs-14 ff-dmsans fw-500 p-1 border rounded-16 mb-0">
                        Step 03
                      </p>
                    </div>
                    <p className="ca-two-body-clr mt-20 mb-0 text-center">
                    Before delivery our team puts the finishing touches on your project including testing, quality assurance, and ensuring best user experience                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="aih-process-item text-center p-3">
                    <img
                      src="assets/img/ai_home/process.png"
                      alt=""
                      className="aih-process-img img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default AiCompanyProcess;
  