import React from 'react';
import { Link } from 'react-router-dom';

export default function DigiWorkProcess() {
  return (
    <section className=" bg-white pt-50 pb-50">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="digi-how-works-left">
              <div>
                <span className="span-arrow">How It Works</span>
                <img
                  src="assets/img/arro-right.svg"
                  className="img-fluid"
                  alt="arrow"
                />
              </div>
              <h2>
                Our bulletproof process to win on
                <span className="text-orange"> social media</span>
              </h2>
              <p>
              Social media management involves a strategic approach to leverage social media platforms to achieve your business objectives. Here are the three key steps involved.
              </p>
              {/* <Link
                to="/request-for-demo"
                className="btn rounded-pill bg-orange mt-3"
              >
                Request a Quoate
              </Link> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="digi-how-works-right mt-5 mt-lg-0">
              <div className="dig-how-steps">
                <ul className="list-unstyled mb-0">
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    <div className="me-3">
                      <img
                        src="assets/img/icons/st-1.png"
                        className="img-fluid mb-3 mb-md-0"
                        alt="icons"
                      />
                    </div>
                    <div>
                      <span className="text-orange fw-bold">Step 1: Strategy Development</span>
                      <h6>Define Your Goals</h6>
                      <p className="m-0">
                      Clearly outline what you want to achieve through social media, such as increasing brand awareness, driving website traffic, or generating leads.  

                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex justify-content-between align-items-center mb-3">
                    <div className="me-3">
                      <img
                        src="assets/img/icons/st-2.png"
                        className="img-fluid mb-3 mb-md-0"
                        alt="icons"
                      />
                    </div>
                    <div>
                      <span className="text-orange fw-bold">Step 2: Content Creation and Curation</span>
                      <h6>Create Engaging Content</h6>
                      <p className="m-0">
                      Develop high-quality content that resonates with your target audience and encourages interaction.
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex justify-content-between align-items-center">
                    <div className="me-3">
                      <img
                        src="assets/img/icons/st-3.png"
                        className="img-fluid mb-3 mb-md-0"
                        alt="icons"
                      />
                    </div>
                    <div>
                      <span className="text-orange fw-bold">Step 3: Engagement and Analytics</span>
                      <h6>Monitor and Respond</h6>
                      <p className="m-0">
                      Actively engage with your audience by responding to comments, messages, and mentions promptly.
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="shape">
                  <ul className="list-unstyled mb-0">
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
