import { Link } from "react-router-dom";

const SoftwareCompanyPortfolio = () => {
  return (
    <>
      <section className="sc-portfolio-section p-50 bg-white position-relative z-1 overflow-hidden border-line-bg">
        <span className="sc-shape-gradient sc-shape-gradient-7 position-absolute z--1"></span>
        <span className="sc-shape-gradient sc-shape-gradient-8 position-absolute z--1"></span>
        <span className="sc-shape-gradient sc-shape-gradient-9 position-absolute z--1"></span>
        <span className="sc-shape-gradient sc-shape-gradient-10 position-absolute z--1"></span>
        <span className="sc-shape-gradient sc-shape-gradient-11 position-absolute z--1"></span>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-8 col-lg-7 pb-5">
              <div className="section-title text-center">
                <h2 >
                Technology we use
                </h2>
                <span >Utilizing smart technology to transform challenges into opportunities</span>
              </div>
            </div>
          </div>
          <div className="row g-4 justify-content-center masonry_grid">
            <div className="col-xl-5 col-lg-7 col-md-6 grid_item">
              <div className="sc-portfolio-card position-relative rounded overflow-hidden active it " style={{height: "300px"}}>
                <img
                  src="assets/img/software-company/pf-1.jpg"
                  alt="not found"
                  className="img-fluid"
                />
                <div className="pf-content-box bg-white p-3  rounded">
                  <Link
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="d-inline-block text-dark badge bg-primary-soft"
                  >
                    AI
                  </Link>
                  <a href="#">
                    <p className="mb-0 mt-3 fw-semibold">
                    Our Artificial Intelligence -powered solutions can help you achieve greater efficiency, innovation, and growth.                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5 col-md-6 grid_item">
              <div className="sc-portfolio-card position-relative rounded overflow-hidden card-sm" style={{height: "300px"}}>
                <img
                  src="assets/img/software-company/pf-2.jpg"
                  alt="not found"
                  className="img-fluid"
                />
                <div className="pf-content-box bg-white p-3  rounded">
                  <Link
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="d-inline-block text-dark badge bg-primary-soft"
                  >
                    Blockchain Technology
                  </Link>
                  <a href="#">
                    <p className="mb-0 mt-3 fw-semibold">
                    Blockchain technology has the potential to transform industries and create new opportunities.</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-7 col-md-6 grid_item">
              <div className="sc-portfolio-card position-relative rounded overflow-hidden card-sm" style={{height: "300px"}}>
                <img
                  src="assets/img/software-company/pf-3.jpg"
                  alt="not found"
                  className="img-fluid"
                />
                <div className="pf-content-box bg-white p-3 rounded">
                  <Link
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="d-inline-block text-dark badge bg-primary-soft"
                  >
                    Cloud Computing
                  </Link>
                  <a href="#">
                    <p className="mb-0 mt-2 fw-semibold">
                    Cloud computing has revolutionized the way businesses operate, offering greater flexibility, scalability, and cost-effectiveness.
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-6 grid_item">
              <div className="sc-portfolio-card position-relative rounded overflow-hidden card-sm" style={{height: "300px"}}>
                <img
                  src="assets/img/software-company/pf-4.jpg"
                  alt="not found"
                  className="img-fluid"
                />
                <div className="pf-content-box bg-white p-3 rounded">
                  <Link
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="d-inline-block text-dark badge bg-primary-soft"
                  >
                    Internet of thing
                  </Link>
                  <a href="#">
                    <p className="mb-0 mt-3 fw-semibold">
                    By incorporating IoT into your tech services business, you can stay ahead of the competition, improve customer satisfaction, and drive growth.                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-7 col-md-6 grid_item">
              <div className="sc-portfolio-card position-relative rounded overflow-hidden card-sm" style={{height: "300px"}}>
                <img
                  src="assets/img/software-company/pf-5.jpg"
                  alt="not found"
                  className="img-fluid"
                />
                <div className="pf-content-box bg-white p-3 rounded">
                  <Link
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="d-inline-block text-dark badge bg-primary-soft"
                  >
                    Machine learning
                  </Link>
                  <a href="#">
                    <p className="mb-0 mt-3 fw-semibold">
                    By enabling computers to learn from data and improve their performance over time, ML can automate all your tasks.</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5 col-md-6 grid_item">
              <div className="sc-portfolio-card position-relative rounded overflow-hidden card-sm" style={{height: "300px"}}>
                <img
                  src="assets/img/software-company/pf-6.jpg"
                  alt="not found"
                  className="img-fluid"
                />
                <div className="pf-content-box bg-white p-3 rounded">
                  <Link
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="d-inline-block text-dark badge bg-primary-soft"
                  >
                    Data analytics
                  </Link>
                  <a href="#">
                    <p className="mb-0 mt-3 fw-semibold">
                    Unlock valuable insights with our data analytics services, empowering you to make informed decisions.                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-center mt-5">
            <Link href="/software-company" className="btn sc-outline-btn">
              View all Item
            </Link>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default SoftwareCompanyPortfolio;
