import { Link } from "react-router-dom";

const CreativeAgencyNewService = () => {
  return (
    <>
      <div className="sections service-section-36">
        <div className="sections__head">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10  col-xxl-6">
                <h1 className="text-center text-white mb-0">
                Best video crafters for you
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-card-36" >
                <div className="mb-30">
                  <img
                    src="assets/img/icon-service-card-1.png"
                    alt="Website Development"
                    className="img-fluid"
                  />
                </div>
                <h5 className="fw-medium text-white mb-3">
                Creative Excellence
                </h5>
                <p className="mb-30 text-white text-opacity-50">
                Our team of talented videographers and editors possesses a passion for storytelling and a keen eye for detail, ensuring that your videos are visually stunning and engaging. 
                </p>
                {/* <Link
                  href="/creative-agency-new"
                  className="btn btn-link text-white p-0"
                >
                  Read More
                </Link> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-card-36" >
                <div className="mb-30">
                  <img
                    src="assets/img/icon-service-card-2.png"
                    alt="Marketing Plans"
                    className="img-fluid"
                  />
                </div>
                <h5 className="fw-medium text-white mb-3">Customized Solutions</h5>
                <p className="mb-30 text-white text-opacity-50">
                We believe in a personalized approach. Our team will work closely with you to understand your unique vision and develop a video concept that aligns with your brand and goals.
                </p>
                {/* <Link
                  href="/creative-agency-new"
                  className="btn btn-link text-white p-0"
                >
                  Read More
                </Link> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-card-36">
                <div className="mb-30">
                  <img
                    src="assets/img/icon-service-card-3.png"
                    alt="Data Security"
                    className="img-fluid"
                  />
                </div>
                <h5 className="fw-medium text-white mb-3">Innovative apparatus</h5>
                <p className="mb-30 text-white text-opacity-50">
                We utilize the latest video production equipment and technology to deliver high-quality, professional videos.
                </p>
                {/* <Link
                  href="/creative-agency-new"
                  className="btn btn-link text-white p-0"
                >
                  Read More
                </Link> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-card-36">
                <div className="mb-30">
                  <img
                    src="assets/img/icon-service-card-4.png"
                    alt="Cloud Hosting"
                    className="img-fluid"
                  />
                </div>
                <h5 className="fw-medium text-white mb-3">Experienced Team</h5>
                <p className="mb-30 text-white text-opacity-50">
                Our team has a proven track record of creating successful videos for various industries and purposes.
                </p>
                {/* <Link
                  href="/creative-agency-new"
                  className="btn btn-link text-white p-0"
                >
                  Read More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreativeAgencyNewService;
