// import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import PaymentGatewayNewInfocard from "../../components/paymentGatewayNew/PaymentGatewayNewInfocard";
import CreativeAgencyNewBlog from "../../components/creativeAgencyNew/CreativeAgencyNewBlog";
import CreativeAgencyNewFeature from "../../components/creativeAgencyNew/CreativeAgencyNewFeature";
import CreativeAgencyNewFeedback from "../../components/creativeAgencyNew/CreativeAgencyNewFeedback";
import CreativeAgencyNewHero from "../../components/creativeAgencyNew/CreativeAgencyNewHero";
import CreativeAgencyNewService from "../../components/creativeAgencyNew/CreativeAgencyNewService";
import CreativeAgencyNewSteps from "../../components/creativeAgencyNew/CreativeAgencyNewSteps";
import CreativeAgencyNewTeam from "../../components/creativeAgencyNew/CreativeAgencyNewTeam";
import FooterOne from "../../layout/Footer/FooterOne";

const VideoMaking = () => {
  return (
    <Layout classOpt="home-36">
      <Navbar navDark />
      <CreativeAgencyNewHero />
      <PaymentGatewayNewInfocard />
      <CreativeAgencyNewService />
      <CreativeAgencyNewSteps />
      {/* <CreativeAgencyNewTeam /> */}
      {/* <CreativeAgencyNewFeature /> */}
      <CreativeAgencyNewFeedback />
      {/* <CreativeAgencyNewBlog /> */}

      <FooterOne
      footerLight ="yes"
        style={{
          background: "url('/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default VideoMaking;
