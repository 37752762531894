import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';
import InquiryForm from './InquiryForm';

const ContactFormThree = () => {
  return (
    <>
      <section className='contact-us pt-50 pb-50 position-relative overflow-hidden'>
        <div className='container'>
          <div className='row justify-content-around'>
             <div className='col-md-4 '>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column'>
                <span className='fad fa-envelope fa-3x text-primary'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>Email Us</h5>
                  <p>
                  Drop us an email at{' '}
                    <strong>hello@senertech.in </strong>
                    and we'll get back to you as soon as possible.
                  </p>
                </div>
                <a
                  href='mailto:hello@senertech.in'
                  className='btn btn-primary mt-auto'
                >
                  Email Us
                </a>
              </div>
            </div>
            <InquiryForm />
          </div>
        </div>
        <div
          className='bg-dark position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z--1 py-5'
          style={{
            background:
              " url('img/shape/dot-dot-wave-shape.svg')no-repeat center top",
          }}
        >
          <div className='bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5'></div>
          <div className='bg-circle rounded-circle circle-shape-1 position-absolute bg-warning right-5'></div>
        </div>
      </section>
    </>
  );
};
export default ContactFormThree;
