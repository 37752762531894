import React from 'react';
import { Link } from 'react-router-dom';
import {Modal} from "react-bootstrap"


const Terms = (
    {
        show,
        onHide
    }
) => {
  return (
    <div>
    <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={onHide}
        size="xl"
    >
        <Modal.Header closeButton>
            <Modal.Title id="check-representative">Terms</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='p-4'>
        <p>This Privacy Policy describes the policies of Senertech Software Private Limited, use and disclosure of your information that we collect when you use our website (https://www.senertech.in) herein after called the “website”. By accessing or using the website, you are consenting to the collection, use and disclosure of your information in accordance with this Privacy Policy. If you do not consent to the same, please do not access or use the website.
        </p>
        <p>We may modify this Privacy Policy at any time without any prior notice to you and will post the revised Privacy Policy on the website. The revised policy will be effective 180 days from when the revised policy is posted in the website and your continued access or use of the website after such time will constitute your acceptance of the revised Privacy Policy. We therefore recommend that you periodically review this page.
        </p>
        <h5 >INFORMATION WE COLLECT</h5>
        <p>We will collect and process the following personal information about you:</p>
        <ul >
            <li >Name</li>
            <li >Email</li>
            <li >Mobile</li>
        </ul>

        <h5  >HOW WE USE YOUR INFORMATION</h5>
        <p>We will use the information that we collect about you for the following purposes:
        </p>
        <ul >
            <li >Marketing, Promotional and/or service offers
            </li>
            <li >Customer feedback collection</li>
        </ul>
        <p>If we want to use your information for any other purpose, we will ask you for consent and will use your information only on receiving your consent and then, only for the purpose(s) for which grant consent unless we are required to do otherwise by law.</p>
        
        <h5>RETENTION OF YOUR INFORMATION</h5>
        <p>We will retain your personal information with us for 90 days to 2 years after user terminate account or for as long as we need it to fulfil the purposes for which it was collected as detailed in this Privacy Policy. We may need to retain certain information for longer periods such as record-keeping / reporting in accordance with applicable law or for other legitimate reasons like enforcement of legal rights, fraud prevention, etc. Residual anonymous information and aggregate information, neither of which identifies you (directly or indirectly), may be stored indefinitely.
        </p>
        <h5>YOUR RIGHTS</h5>
        <p>Depending on the law that applies, you may have a right to access and rectify or erase your personal data or receive a copy of your personal data, restrict or object to the active processing of your data, ask us to share (port) your personal information to another entity, withdraw any consent you provided to us to process your data, a right to lodge a complaint with a statutory authority and such other rights as may be relevant under applicable laws. To exercise these rights, you can contact us through the details shown on contact page of this website; we will respond to your request in accordance with applicable law.</p>
        <p>You may opt-out of direct marketing communications or the profiling we carry out for marketing purposes by clicking the unsubscribe link provided in the footer of email or massages which we sent to you.</p>
        <p>Do note that if you do not allow us to collect or process the required personal information or withdraw the consent to process the same for the required purposes, you may not be able to access or use the website for which your information was sought.</p>
        <h5>SECURITY</h5>
        <p>The security of your information is important to us and we will use reasonable security measures to prevent the loss, misuse or unauthorized alteration of your information under our control. However, given the inherent risks, we cannot guarantee absolute security and consequently, we cannot ensure or warrant the security of any information you transmit to us and you do so at your own risk.
        </p>
        </div>
        </Modal.Body>
    </Modal>
</div>
  )
};

export default Terms;
