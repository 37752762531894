const PaymentGatewayNewInfocard = () => {
  return (
    <>
      <div className="pay-gw-info-card-area pt-50 pb-50">
        <div className="container">
          <div className="mb-20">
            <div className="row align-items-center justify-content-between">
              <div className="col-xxl-5 col-lg-7">
                <h2 className="pay-gw-title pay-gw-color fs-42 ff-risk-pri mb-20 ">
                Senertech is your trusted partner for creating compelling and effective videos
                </h2>
                <p className="mb-20">
                We are reliable in producing engaging and impactful videos that grab attention and deliver results. Our talented team of videographers and editors provides a diverse array of video production services customized to meet your unique requirements. </p>
                <ul className="pay-gw-service-list list-unstyled d-flex flex-column gap-4">
                  <li className="d-flex align-items-center gap-4 pay-gw-color">
                    <i className="fa-solid fa-circle-check"></i>
                    <p className="fs-20 ff-risk-pri fw-600 mb-0">
                   Time and cost-effective solutions
                    </p>
                  </li>
                  <li className="d-flex align-items-center gap-4 pay-gw-color">
                    <i className="fa-solid fa-circle-check"></i>
                    <p className="fs-20 ff-risk-pri fw-600 mb-0">
                   Improved conversion rates
                    </p>
                  </li>
                  <li className="d-flex align-items-center gap-4 pay-gw-color">
                    <i className="fa-solid fa-circle-check"></i>
                    <p className="fs-20 ff-risk-pri fw-600 mb-0">
                    Enhanced brand visibility
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-5 col-lg-5">
                <img
                  src="assets/img/home_34/service.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="pay-gw-card-item p-4 rounded-3 mt-20">
                <div className="d-flex align-items-center gap-4 mb-30">
                  <div className="pay-card-icon-wpreer position-relative z-1">
                    <div className="pay-card-icon">
                      <img
                        src="assets/img/home_34/card-1.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <img
                      src="assets/img/home_34/card-shape.png"
                      alt=""
                      className="shape-1 position-absolute z--1 img-fluid"
                    />
                  </div>
                  <h5 className="pay-gw-color fs-24 ff-risk-pri mb-0">
                 Increased Engagement{" "}
                  </h5>
                </div>
                <p className="pay-gw-color mt-20 mb-0">
                Visually appealing videos can draw in your target audience and keep them engaged.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="pay-gw-card-item p-4 rounded-3 mt-20">
                <div className="d-flex align-items-center gap-4 mb-30">
                  <div className="pay-card-icon-wpreer position-relative z-1">
                    <div className="pay-card-icon">
                      <img
                        src="assets/img/home_34/card-2.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <img
                      src="assets/img/home_34/card-shape.png"
                      alt=""
                      className="shape-1 position-absolute z--1 img-fluid"
                    />
                  </div>
                  <h5 className="pay-gw-color fs-24 ff-risk-pri mb-0">
                  Stronger Brand Story
                  </h5>
                </div>
                <p className="pay-gw-color mt-20 mb-0">
                Share your brand's story effectively through compelling visuals and storytelling.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="pay-gw-card-item p-4 rounded-3 mt-20">
                <div className="d-flex align-items-center gap-4 mb-30">
                  <div className="pay-card-icon-wpreer position-relative z-1">
                    <div className="pay-card-icon">
                      <img
                        src="assets/img/home_34/card-3.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <img
                      src="assets/img/home_34/card-shape.png"
                      alt=""
                      className="shape-1 position-absolute z--1 img-fluid"
                    />
                  </div>
                  <h5 className="pay-gw-color fs-24 ff-risk-pri mb-0">
                  Data-Driven Insights
                  </h5>
                </div>
                <p className="pay-gw-color mt-20 mb-0">
                We leverage analytics to measure the impact of your videos and optimize future campaigns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentGatewayNewInfocard;
