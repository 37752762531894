// import icon from "assets/img/icons/check.svg"
const AiContentGeneratorTab = () => {
  return (
    <>
      <div className="ail-feature-area mt-100 pt-50 pb-50">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-8">
              <p className="ail-sub-title text-black fw-500 ah-input-bg d-inline-flex align-items-center gap-2 rounded-5 mb-20">
                <span className="ail-gd-bg"></span> What we do
              </p>
              <h2 className="ail-title text-black fs-48 fw-600">
                What amazing content you can create {""}
                <span className="ail-highlighted-text">with Senertech</span>?
              </h2>
            </div>
            {/* <div className="col-xl-5 col-lg-4">
              <div className="text-end">
                <div className="p-3 d-inline-flex justify-content-end  rounded-circle">
                  <a
                    href=""
                    className="aih-arrow-btn d-inline-flex align-items-center justify-content-center position-relative"
                  >
                    <svg
                      width="24"
                      height="39"
                      viewBox="0 0 24 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2L13.5 2ZM10.9393 38.0607C11.5251 38.6464 12.4749 38.6464 13.0607 38.0607L22.6066 28.5147C23.1924 27.9289 23.1924 26.9792 22.6066 26.3934C22.0208 25.8076 21.0711 25.8076 20.4853 26.3934L12 34.8787L3.51472 26.3934C2.92893 25.8076 1.97919 25.8076 1.3934 26.3934C0.807613 26.9792 0.807613 27.9289 1.3934 28.5147L10.9393 38.0607ZM10.5 2L10.5 37L13.5 37L13.5 2L10.5 2Z"
                        fill="#666666"
                      ></path>
                    </svg>
                    <img
                      className="aih-arrow-btn-img rotate-ani position-absolute"
                      src="assets/img/ail_home/arrow-btn.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div> */}
          </div>
          <div className="mt-40">
            <div className="row">
              <div className="col-12">
                <ul
                  className="nav ail-feature-tab align-items-center justify-content-between gap-2 mb-0"
                  id="nav-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#tab-1"
                      data-bs-toggle="tab"
                      data-bs-target="#tab-1"
                      role="tab"
                      aria-selected="false"
                    >
                      <img src="assets/img/ail_home/ft-icon-1.png" alt="" />
                      <img
                        src="assets/img/ail_home/ft-icon-1-gd.png"
                        alt=""
                        className="active"
                      />
                      <p>Digital Designs</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#tab-2"
                      data-bs-toggle="tab"
                      data-bs-target="#tab-2"
                      role="tab"
                      aria-selected="false"
                    >
                      <img src="assets/img/ail_home/ft-icon-2.png" alt="" />
                      <img
                        src="assets/img/ail_home/ft-icon-2-gd.png"
                        alt=""
                        className="active"
                      />
                      <p>Brand Identities</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#tab-3"
                      data-bs-toggle="tab"
                      data-bs-target="#tab-3"
                      role="tab"
                      aria-selected="false"
                    >
                      <img src="assets/img/ail_home/ft-icon-3.png" alt="" />
                      <img
                        src="assets/img/ail_home/ft-icon-3-gd.png"
                        alt=""
                        className="active"
                      />
                      <p>Print Designs</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#tab-4"
                      data-bs-toggle="tab"
                      data-bs-target="#tab-4"
                      role="tab"
                      aria-selected="false"
                    >
                      <img src="assets/img/ail_home/ft-icon-4.png" alt="" />
                      <img
                        src="assets/img/ail_home/ft-icon-4-gd.png"
                        alt=""
                        className="active"
                      />
                      <p>Promotional Designs</p>
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade pt-40 active show"
                    id="tab-1"
                    role="tabpanel"
                  >
                    <div className="ail-ft-content ail-main-bg p-5 pt-60 pb-60 rounded-10 position-relative z-1 overflow-hidden">
                      <div className="row ">
                        <div className="col-lg-6">
                          <div className="ail-op-bg p-4 rounded-10">
                            <img
                              src="assets/img/ail_home/tab-1.png"
                              alt=""
                              className="w-100 img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-evenly text-black">


                          <div className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Website Designing</div>

                          <div className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Mobile App Designing</div>

                          <div className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Social Media Creatives</div>

                          <div className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Presentations</div>

                          <div className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Infographics & Illustrations</div>

                          <div className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            eBooks</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade pt-40 active show"
                    id="tab-2"
                    role="tabpanel"
                  >
                    <div className="ail-ft-content ail-main-bg p-5 pt-60 pb-60 rounded-10 position-relative z-1 overflow-hidden">
                      <div className="row ">
                        <div className="col-lg-6">
                          <div className="ail-op-bg p-4 rounded-10">
                            <img
                              src="assets/img/ail_home/tab-1.png"
                              alt=""
                              className="w-100 img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-evenly text-black">
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Brand Logo</span>
                          {/* </div> */}
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Brand Color Theme</span>
                          {/* </div> */}
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Letterhead Design</span>
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Business Card Design</span>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade pt-40 active show"
                    id="tab-3"
                    role="tabpanel"
                  >
                    <div className="ail-ft-content ail-main-bg p-5 pt-60 pb-60 rounded-10 position-relative z-1 overflow-hidden">
                      <div className="row ">
                        <div className="col-lg-6">
                          <div className="ail-op-bg p-4 rounded-10">
                            <img
                              src="assets/img/ail_home/tab-1.png"
                              alt=""
                              className="w-100 img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-evenly text-black">
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Brochures</span>
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Flyers</span>
                          {/* </div> */}
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Magazine</span>
                          {/* </div> */}
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Sign Boards</span>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade pt-40 active show"
                    id="tab-4"
                    role="tabpanel"
                  >
                    <div className="ail-ft-content ail-main-bg p-5 pt-60 pb-60 rounded-10 position-relative z-1 overflow-hidden">
                      <div className="row ">
                        <div className="col-lg-6">
                          <div className="ail-op-bg p-4 rounded-10">
                            <img
                              src="assets/img/ail_home/tab-1.png"
                              alt=""
                              className="w-100 img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-evenly text-black">
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Product Packaging</span>
                          {/* </div> */}
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Product Showcase</span>
                          {/* </div> */}
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Stickers</span>
                          {/* </div> */}
                          {/* <div> */}
                          <span className="fs-4 text-primary-emphasis ">
                            <img
                              src="assets/img/icons/check.svg"
                              alt="photo"
                              style={{ width: '25px', height: '25px' }}
                              className="me-2" />
                            Carton Designs</span>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AiContentGeneratorTab;
