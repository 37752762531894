
import React from 'react';
import HelpDeskSupport from "../../components/helpDeskHome/HelpDeskSupport";
import DigiWhyChoose from '../../components/others/DigiWhyChoose';
import DigiSerives from '../../components/services/DigiSerives';
import DigiWorkProcess from '../../components/work-process/DigiWorkProcess';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroFourteen from "./HeroFourteen";

const SocialMedia = () => {
  return (
    <Layout>
      <Navbar />
      <HeroFourteen />
      {/* <CustomerBrand /> */}
      <DigiSerives />
      {/* <CtaFour /> */}
      <HelpDeskSupport/>
      <DigiWhyChoose />
      
      <DigiWorkProcess />
      {/* <DigiIntegration /> */}
      {/* <DigiBlog /> */}
      {/* <DigiContact /> */}
      <FooterOne
        // style={{
        //   background:
        //     "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        // }}
      />
    </Layout>
  );
};


export default SocialMedia;
